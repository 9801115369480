import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ButtonsPage from './components/ButtonsPage';
import ComponentsPage from './components/ComponentsPage';
import Navigation from './components/Navigation';
import './index.css';

import styled, {
	ThemeProvider
} from 'styled-components';

const theme = {
	red: '#E3192E',
	grey: '#2D2D2D',
	darkGrey: '#232323'
}

const ComponentLibrary = styled.div`

`;

class App extends Component {

	render() {
		return (
			<ThemeProvider theme={theme}>
				<>
					<ComponentLibrary>
						<Router>
							<Navigation  />						
							<Route path="/" exact component={ButtonsPage} />
							<Route path="/components" exact component={ComponentsPage} />
						</Router>
					</ComponentLibrary>
				</>
			</ThemeProvider>
		);
	}
}

export default App;
