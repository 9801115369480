import React, { Component } from 'react';
import styled from 'styled-components';

import textIcon from '../imgs/text-icon.svg';
import animationIcon from '../imgs/animation-icon.svg';
import colourIcon from '../imgs/colour-icon.svg';

const SiteTopBar = styled.div`
    background: ${props => props.theme.grey};
    color: white;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    span {
        font-size: 18px;
        font-weight: 500;
        padding: 0 0 0 25px;
        flex-grow: 1;
    }
    
    .colourfilter,
    .filter,
    .search {
        position: relative;
        width: 80px;
        height: 80px;
        border-left: 1px solid #474747;
        transition: all 300ms;
        cursor: pointer;

        &:hover,
        &:focus {
            background: black;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 30px;
        }

        &.__active {
            background: black;
        }
    }
`;

const SearchButton = styled.button``;

class TopBar extends Component {
    render() {
        return (
            <SiteTopBar>
                <span>Button Library</span>
                <div className={this.props.isColourFiltersOpen ? 'filter __active' : 'filter'} onClick={this.props.toggleColourFilters}>
                    <img src={colourIcon} alt="" />
                </div>
                <div className={this.props.isAnimationsFiltersOpen ? 'filter __active' : 'filter'} onClick={this.props.toggleAnimationFilters}>
                    <img src={animationIcon} alt="" />
                </div>
                <div className={this.props.isTextFiltersOpen ? 'filter __active' : 'filter'} onClick={this.props.toggleTextFilters}>
                    <img src={textIcon} alt="" />
                </div>

                <SearchButton />
            </SiteTopBar>
        );
    }
}

export default TopBar;
