import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;


class Button13alt extends Component {
    render() {
        const html = `<button class="strikethrough">Strikethrough</button>`;
        const css = `position: relative;
background: ${this.props.buttonSettings.primaryCol};
outline: none;
border: none;
border-radius: ${this.props.buttonSettings.borderRadius}px;
color: ${this.props.buttonSettings.textCol};
cursor: pointer;
padding: 25px 50px;
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
&::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${this.props.buttonSettings.secondaryCol};
    transform-origin: bottom left;
    transform: scaleX(0);
    transition: transform ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
}
&:hover::before {
    transform-origin: bottom right;
    transform: scaleX(1);
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button13alt;
