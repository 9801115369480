import React, { Component } from 'react';
import TimingEditor from './TimingEditor';
import RangeSlider from './RangeSlider';

class AnimationFilters extends Component {
    
    handleBorderRadiusChange = radius => {
        this.props.setButtonSetting('borderRadius', radius);
    };
    
    render() {
        return (
            <div className="filters-animations">

                <div className="filters-animation">
                    <TimingEditor
                        buttonSettings={this.props.buttonSettings}
                        setButtonSetting={this.props.setButtonSetting}
                    />
                </div>

                <div className="filters-list">
                    <p className="filters-title">Border Radius</p>
                    <RangeSlider
                        value={this.props.buttonSettings.borderRadius}
                        handleChange={this.handleBorderRadiusChange}
                        min="0"
                        max="40"
                        step="1"
                    />
                </div>
            
            </div>
        );
    }
}

export default AnimationFilters;
