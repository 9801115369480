import React, { Component } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';

const SiteNav = styled.div`
    background: #232323;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sitenav-title {
        font-weight: 600;
    }
`;

const NavList = styled.nav`
    ul {
        display:flex;
        li {
            &.active {
                a {
                    background:#333;
                }
            }
            a {
                color: white;
                transition: all 300ms;
                padding: 15px 15px 14px 15px;
                letter-spacing: 0.3px;

                &:hover {
                    background: #333;
                }
            }
        }
    }
`;

class Navigation extends Component {
    render() {
        console.log(this.props);
        return (
            <SiteNav>
                <p className="sitenav-title">Component Library</p>

                <NavList>
                    <ul>
                        <li>
                            <Link to="/">Buttons</Link>
                        </li>
                        <li>
                            <Link to="/components">Components</Link>
                        </li>
                    </ul>
                </NavList>
            </SiteNav>
        );
    }
}

export default Navigation;
