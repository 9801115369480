import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;

let element;
let blob;
let fromTop;
let fromLeft;
let posY;
let posX;
const handleMouseMove = (e) => {
    element = e.currentTarget;
    blob = element.querySelector('.circle');
    fromTop = element.offsetTop;
    fromLeft = element.offsetLeft;
    posY = ((fromTop - e.pageY) + 25) * -1 + "px";
    posX = ((fromLeft - e.pageX) + 25) * -1 + "px";
    blob.style.top = posY;
    blob.style.left = posX;
}

class Button11 extends Component {
    render() {
        const html = `<button>
    <span>Button</span>
</button>`;
        const css = `border-radius: ${this.props.buttonSettings.borderRadius}px;
background: ${this.props.buttonSettings.primaryCol};
color: ${this.props.buttonSettings.textCol};
position: relative;
font-weight: ${this.props.buttonSettings.fontWeight};
padding: 25px 50px;
cursor: pointer;
overflow: hidden;
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
z-index: 1;
:hover {
    > span.circle {
        transform: scale(1);
    }
}
span {
    position: absolute;
    width: 60px;
    height: 60px;
    background: ${this.props.buttonSettings.secondaryCol};
    border-radius: 50%;
    pointer-events: none;
    transform: scale(0);
    transition: top 0s ease, left 0s ease, transform ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
    z-index: -1;
}`;
        const js = `$('.mousefollow').on('mousemove', function (e) {
var buttonBlob = $(this).find('.circle');

var fromTop = $(this).offset().top;
var fromLeft = $(this).offset().left;
var posY = ((fromTop - e.pageY) + 25) * -1 + "px";
var posX = ((fromLeft - e.pageX) + 25) * -1 + "px";

buttonBlob.css("top", posY);
buttonBlob.css("left", posX);
});`;
        return (
            <>
                <Button onMouseMove={(e) => handleMouseMove(e)} className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    <span class="circle"></span>
                    {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                </Button>
                <ClipCopy css={css} html={html+"\n\n"+js} />
            </>
        );
    }
}

export default Button11;
