import React, { Component } from 'react';
import styled from 'styled-components';
import ButtonBlock from './ButtonBlock';
import Button1 from './buttons/Button1';
import Button2L from './buttons/Button2L';
import Button2B from './buttons/Button2B';
import Button2R from './buttons/Button2R';
import Button2T from './buttons/Button2T';
import Button2Lalt from './buttons/Button2Lalt';
import Button2Balt from './buttons/Button2Balt';
import Button2Ralt from './buttons/Button2Ralt';
import Button2Talt from './buttons/Button2Talt';
import Button3L from './buttons/Button3L';
import Button3B from './buttons/Button3B';
import Button3R from './buttons/Button3R';
import Button3T from './buttons/Button3T';
import Button4L from './buttons/Button4L';
import Button4LR from './buttons/Button4LR';
import Button4C from './buttons/Button4C';
import Button5 from './buttons/Button5';
import Button6 from './buttons/Button6';
import Button7 from './buttons/Button7';
import Button8BL from './buttons/Button8BL';
import Button8TL from './buttons/Button8TL';
import Button8TR from './buttons/Button8TR';
import Button8BR from './buttons/Button8BR';
import Button8C from './buttons/Button8C';
import Button9 from './buttons/Button9';
import Button9alt from './buttons/Button9alt';
import Button10B from './buttons/Button10B';
import Button10BT from './buttons/Button10BT';
import Button10C from './buttons/Button10C';
import Button11 from './buttons/Button11';
import Button12 from './buttons/Button12';
import Button13 from './buttons/Button13';
import Button13alt from './buttons/Button13alt';
import Button13alt2 from './buttons/Button13alt2';
import Button14 from './buttons/Button14';

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: hidden;
    text-align: center;
    width: 100%;
    .buttonswrap {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        overflow: hidden;
        text-align: center;
    }
    .button-direction-wrap {
        padding: 10px 25px;
        display: flex;
        flex-wrap: wrap;
    }
    .button-direction {
        display: inline-block;
        margin: 0 10px;
        outline: none;
    }

    .button-title {
        padding: 50px 25px 25px 25px;
        margin: 0 0 20px 0;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
`;

class ButtonList extends Component {
    render() {
        return (
            <Buttons className="buttonswrap">
                <ButtonBlock>
                    <div className="button-title">Fade1</div>
                    <Button1 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Fade2</div>
                    <Button6 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide1</div>
                    <Button2L buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide2</div>
                    <Button2B buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide3</div>
                    <Button2R buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide4</div>
                    <Button2T buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide5</div>
                    <Button2Lalt buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide6</div>
                    <Button2Balt buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide7</div>
                    <Button2Ralt buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide8</div>
                    <Button2Talt buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide9</div>
                    <Button3L buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide10</div>
                    <Button3B buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide11</div>
                    <Button3R buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide12</div>
                    <Button3T buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Bars1</div>
                    <Button4L buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Bars2</div>
                    <Button4LR buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Bars3</div>
                    <Button4C buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide13</div>
                    <Button5 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Slide14</div>
                    <Button7 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Circle1</div>
                    <Button8C buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Circle2</div>
                    <Button8BL buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Circle3</div>
                    <Button8TL buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Circle4</div>
                    <Button8TR buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Circle5</div>
                    <Button8BR buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Highlight</div>
                    <Button9 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Underline</div>
                    <Button9alt buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Pillars1</div>
                    <Button10B buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Pillars2</div>
                    <Button10BT buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Pillars3</div>
                    <Button10C buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Mouse follow</div>
                    <Button11 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Strikethrough</div>
                    <Button12 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Underline</div>
                    <Button13 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Underline</div>
                    <Button13alt buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Underline</div>
                    <Button13alt2 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
                <ButtonBlock>
                    <div className="button-title">Underline</div>
                    <Button14 buttonSettings={this.props.buttonSettings} />
                </ButtonBlock>
            </Buttons>
        );
    }
}

export default ButtonList;
