import React, { Component } from 'react';
import FontPicker from "font-picker-react";
import RangeSlider from './RangeSlider';

class TextFilters extends Component {
    state = {
        activeFontFamily: 'Nunito Sans'
    }

    render() {
        return (
            <div className="filters-wrap">
                <div className="filters-textfilters">   
                    <div className="filters-block">
                        <p className="filters-title">Font Family</p>
                        <FontPicker
                            apiKey="AIzaSyBHjQvhl6xPY8ItMxCGflgXV5j86xAdoMc"
                            activeFontFamily={this.state.activeFontFamily}
                            onChange={nextFont =>
                                this.setState({
                                    activeFontFamily: nextFont.family,
                                })
                            }
                            />
                    </div>
                    <div className="filters-block">
                        <p className="filters-title">Font Weight</p>
                        <select value={this.props.buttonSettings.fontWeight} onChange={(e) => { this.props.setButtonSetting('fontWeight', e.target.value)}} className="filters-select">
                            <option>100</option>
                            <option>200</option>
                            <option>300</option>
                            <option>400</option>
                            <option>500</option>
                            <option>600</option>
                            <option>700</option>
                            <option>800</option>
                            <option>900</option>
                        </select>
                    </div>
                    <div className="filters-block">
                        <p className="filters-title">Letter Spacing (px)</p>
                        <input step="0.1" value={this.props.buttonSettings.letterSpacing} onChange={(e) => { this.props.setButtonSetting('letterSpacing', e.target.value)}} className="filters-input" type="number" />
                    </div>
                </div>
            </div>
        );
    }
}

export default TextFilters;
