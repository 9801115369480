import React, { Component } from 'react';
import styled from 'styled-components';

import ComponentList from './ComponentList';
import ComponentCategories from './ComponentCategories';

const Wrapper = styled.div`
    @media(min-width:768px) {
        display:flex;
    }
    .wrapper-cat {
        flex:0 0 20%;

        @media(min-width:1200px) {
            flex:0 0 15%;
        }
    }

    .wrapper-list {
        flex:0 0 80%;
        @media(min-width:1200px) {
            flex:0 0 85%;
        }
        @media(min-width:768px) {
            height:calc(100vh - 52px);
            overflow:auto;
        }
    }
`;


class ComponentsPage extends Component {
    state = {
        activeCategory: 'Navigation',
        componentsCategory: {
            category1: "Navigation",
            category2: "Interactive",
            category3: "JavaScript",
            category4: "Miscellaneous",
            category5: "Video"
        },
    }

    setActiveCategory = (category) => {
        this.setState({
            activeCategory: category
        });
    }

    render() {
        return (
            <Wrapper>
                <div className="wrapper-cat">
                    <ComponentCategories categories={this.state.componentsCategory} activeCategory={this.state.activeCategory} setActiveCategory={this.setActiveCategory} />
                </div>

                <div className="wrapper-list">
                    <ComponentList categories={this.state.componentsCategory} activeCategory={this.state.activeCategory} />
                </div>
            </Wrapper>
        )
    }
}

export default ComponentsPage;
