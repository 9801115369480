import React, { Component } from 'react';
import styled from 'styled-components';

const ButtonWrap = styled.div`
    width: 100%;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    overflow: hidden;
    @media (min-width: 400px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 25%;
    }
`;

class ButtonBlock extends Component {
    render() {
        return (
            <ButtonWrap>
                <div className="buttonwrap-button">{this.props.children}</div>
            </ButtonWrap>
        );
    }
}

export default ButtonBlock;
