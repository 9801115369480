import React, { Component } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';

const Picker = styled.div``;

class ColourPicker extends Component {
    render() {
        return (
            <Picker>
                <SketchPicker color={this.props.color} onChange={colour => this.props.changeMethod(colour.hex)} />
            </Picker>
        );
    }
}

export default ColourPicker;
