/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import styled from 'styled-components';

const List = styled.ul`
    border-left:2px solid #eee;
    height:100%;

`;

const ComponentBlock = styled.div`
    .componentblock-header {
        color:white;
        padding:20px 25px;
        background: black;
        font-weight: 700;
    }
`;

const SingleComponent = styled.iframe`
    width: 100%;
    height: 700px;
`

class ComponentsList extends Component {
    state = {
        components: [
            {
                title: 'Megamenu',
                category: 1,
                codepenId: 'RwwZpdL',
                codepenUser: 'Chris2733'
            },
            {
                title: 'Slider Progress Bar',
                category: 2,
                codepenId: 'XWWEjJN',
                codepenUser: 'Chris2733'
            },
            {
                title: 'Subtle mouse follow',
                category: 2,
                codepenId: 'RwPZQPG',
                codepenUser: 'jonsp'
            },
            {
                title: 'Line by line text animate in',
                category: 3,
                codepenId: 'PoqYVZv',
                codepenUser: 'jonsp'
            },
            {
                title: 'Individual letters animated',
                category: 3,
                codepenId: 'LYEMRGV',
                codepenUser: 'bongocat'
            },
            {
                title: 'Animated progress ring',
                category: 3,
                codepenId: 'GRJKrYv',
                codepenUser: 'jonsp'
            },
            {
                title: 'Animated progress ring',
                category: 2,
                codepenId: 'VwLzbxb',
                codepenUser: 'preghostphase'
            },
            {
                title: 'Click and drag slide',
                category: 2,
                codepenId: 'VwYeYEE',
                codepenUser: 'jonsp'
            },
            {
                title: 'Custom scrollbars',
                category: 1,
                codepenId: 'rNONWKR',
                codepenUser: 'Chris2733'
            },
            {
                title: 'Scroll it',
                category: 1,
                codepenId: 'bGdYVBm',
                codepenUser: 'preghostphase'
            },
            {
                title: 'Clipboard JS',
                category: 3,
                codepenId: 'QWjqvjP',
                codepenUser: 'chris2733'
            },
            {
                title: 'Video embed',
                category: 5,
                codepenId: 'xxbKwMm',
                codepenUser: 'chris2733'
            },
        ]
    }

    render() {
        return (
            // <List>
            //    {this.state.components.filter(component => this.props.activeCategory === component.category).map(component => {
            //             return (
            //            <ComponentBlock>
            //                 <div className="componentblock-header">{component.title}</div>
            //                     <Codepen
            //                         title={component.title}
            //                         hash={component.codepenId}
            //                         user={component.codepenUser}
            //                         height="500"
            //                         defaultTab="result"
            //                         preview="true"
            //                         loader={() => <div>Loading...</div>}
            //                     />
            //            </ComponentBlock>
            //        )
            //    })}
            // </List>
            <List>
               {this.state.components.filter(component => this.props.activeCategory === this.props.categories["category" + component.category]).map((component, id) => {
                        return (
                        <ComponentBlock>
                                <div className="componentblock-header">{component.title}</div>
                                <SingleComponent scrolling="no" title="Subtle mouse follow" src={`https://codepen.io/${component.codepenUser}/embed/${component.codepenId}?height=265&theme-id=dark&default-tab=result`} frameborder="no" allowtransparency="true" allowfullscreen="true"></SingleComponent>
                        </ComponentBlock>
                   )
               })}
            </List>
        )
    }
}

export default ComponentsList;
