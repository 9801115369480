import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;


class Button14 extends Component {
    render() {
        const html = `<button class="strikethrough">Strikethrough</button>`;
        const css = `position: relative;
background: ${this.props.buttonSettings.primaryCol};
border-radius: ${this.props.buttonSettings.borderRadius}px;
color: ${this.props.buttonSettings.textCol};
display: inline-block;
padding: 25px 50px;
border: none;
text-align: center;
text-decoration: none !important;
font-size: 1rem;
white-space: nowrap;
position: relative;
transition: all ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
box-shadow: 0 0.91em 0 -0.9375em #000;
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
&:before,
&:after {
    content: "";
    display: block;
    top: calc(100% + -0.030em);
    width: auto;
    height: auto;
    position: absolute;
    border-width: 0 .95em 0 0;
    border-color: #0000;
    border-style: solid;
    background: none;
    transition: all ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
}
&::before {
    left: 0;
    border-width: 0 1em 0 0;
    border-right-color: ${this.props.buttonSettings.secondaryCol};
}
&::after {
    right: 0;
    border-top-color: ${this.props.buttonSettings.secondaryCol};
    border-width: 0 1em 0 0;
}
&:hover {
    color: ${this.props.buttonSettings.secondaryCol};
    transform: scale(1.01);
    box-shadow: 0 0.91em 0 -0.625em ${this.props.buttonSettings.secondaryCol};
    &::before {
        border-width: 0 .66em .3125em 0;
    }
    &::after {
        border-width: .3125em .66em 0 0;
    }
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button14;
