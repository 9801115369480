import React, { Component } from 'react';
import styled from 'styled-components';

const Categories = styled.ul`
    li {
        padding:15px 20px;
        border-bottom:2px solid #eee;

        &.__active {
            background:#eee;
        }
    }
`;

class ComponentCategories extends Component {
    createCategory = () => {
        let list = [];
        for (const prop in this.props.categories) {
          list.push(<li
              className={this.props.activeCategory === this.props.categories[prop] && '__active'}
              onClick={() => { this.props.setActiveCategory(this.props.categories[prop])}}>
              {this.props.categories[prop]}
              </li>)
        }
        return list;
    }
    render() {
        return (
            <Categories>
                {this.createCategory()}
            </Categories>
        )
    }
}

export default ComponentCategories;
