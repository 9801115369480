import React, { Component } from 'react';
import styled from 'styled-components';
import Filters from './Filters';
import TextFilters from './TextFilters';
import ColourFilters from './ColourFilters';
import AnimationFilters from './AnimationFilters';
import TopBar from './TopBar';
import SearchField from './SearchField';
import ButtonTextField from './ButtonTextField';

const SiteHeader = styled.header``;

class ButtonsHeader extends Component {
    state = {
        isColourFiltersOpen: false,
        isTextFiltersOpen: false,
        isAnimationsFiltersOpen: false,
    };

    toggleColourFilters = () => {
        this.setState({
            isColourFiltersOpen: !this.state.isColourFiltersOpen,
            isTextFiltersOpen: false,
            isAnimationsFiltersOpen: false,
        });
    };

    toggleAnimationFilters = () => {
        this.setState({
            isColourFiltersOpen: false,
            isAnimationsFiltersOpen: !this.state.isAnimationsFiltersOpen,
            isTextFiltersOpen: false,
        });
    };

    toggleTextFilters = () => {
        this.setState({
            isColourFiltersOpen: false,
            isAnimationsFiltersOpen: false,
            isTextFiltersOpen: !this.state.isTextFiltersOpen,
        });
    };


    render() {
        return (
            <SiteHeader>
                {this.state.isTextFiltersOpen && (
                    <Filters>
                        <TextFilters
                            buttonSettings={this.props.buttonSettings}
                            setButtonSetting={this.props.setButtonSetting}
                        />
                    </Filters>
                )}
                {this.state.isColourFiltersOpen && (
                    <Filters>
                        <ColourFilters
                            buttonSettings={this.props.buttonSettings}
                            setButtonSetting={this.props.setButtonSetting}
                        />
                    </Filters>
                )}
                {this.state.isAnimationsFiltersOpen && (
                    <Filters>
                        <AnimationFilters
                            buttonSettings={this.props.buttonSettings}
                            setButtonSetting={this.props.setButtonSetting}
                        />
                    </Filters>
                )}
                
                <TopBar
                    toggleColourFilters={this.toggleColourFilters}
                    toggleAnimationFilters={this.toggleAnimationFilters}
                    toggleTextFilters={this.toggleTextFilters}
                    {...this.state}
                />
                <ButtonTextField
                    buttonSettings={this.props.buttonSettings}
                    setButtonSetting={this.props.setButtonSetting}
                />
            </SiteHeader>
        );
    }
}

export default ButtonsHeader;
