import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;

class Button7 extends Component {
    render() {
        const html = `<button>
    Button
    <span>Button</span>
    <span>Button</span>
</button>`;
        const css = `position: relative;
overflow: hidden;
display: inline-block;
outline: none;
padding: 25px 50px;
cursor: pointer;
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
background: ${this.props.buttonSettings.primaryCol};
transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
border-radius: ${this.props.buttonSettings.borderRadius}px;
span {
    z-index: 2;
    color: ${this.props.buttonSettings.textCol};
    transition: inherit;
    position: relative;
}
:before {
    background: ${this.props.buttonSettings.secondaryCol};
}
:after {
    background: ${this.props.buttonSettings.secondaryCol};
    opacity:0.5;
}
:before,
:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: -100%;
    left: 0;
    transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
    z-index: 1;
}
:hover {
    span {
        color: ${this.props.buttonSettings.textHoverCol};
    }
    :before {
        transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${
            this.props.buttonSettings.cubicBezier
        }) ${this.props.buttonSettings.animationSpeed * 0.3}ms;
    }
    :before,
    :after {
        top: 0;
    }
}`;
        return (
            <>
                <Button className="apply-font"  buttonSettings={this.props.buttonSettings} styles={css}>
                    <span className="apply-font">
                        {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    </span>
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button7;
