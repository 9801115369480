import React, { Component } from 'react';
import styled from 'styled-components';

import ButtonsHeader from './ButtonsHeader';
import ButtonList from './ButtonList';

const Page = styled.div`
    overflow: hidden;
`;

class ButtonsPage extends Component {
    state = {
        activeFontFamily: "Open Sans",
        buttonSettings: {
            buttonText: 'Button',
            animationSpeed: 300,
            cubicBezier: [0.2, 0.2, 0.8, 0.8],
            primaryCol: '#ebebeb',
            secondaryCol: '#e4192f',
            tertiaryCol: '#000000',
            textCol: '#4e4e4e',
            textHoverCol: '#ffffff',
            borderRadius: 0,
            fontWeight: 300,
            letterSpacing: 0
        },
    };

    setButtonSetting = (property, value) => {
        const currentSettings = this.state.buttonSettings;
        currentSettings[property] = value;

        this.setState({
            buttonSettings: {
                ...currentSettings,
            },
        });
    };

    render() {
        return (
            <Page>
                <ButtonsHeader setButtonSetting={this.setButtonSetting} buttonSettings={this.state.buttonSettings} />
                <ButtonList buttonSettings={this.state.buttonSettings} />
            </Page>
        );
    }
}

export default ButtonsPage;
