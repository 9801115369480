import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;

class Button2R extends Component {
    render() {
        const html = `<button>
    <span>Button</span>
</button>`;
        const css = `display: inline-block;
background: left top / 210% 100% linear-gradient(to right, ${this.props.buttonSettings.primaryCol} 50%, ${
            this.props.buttonSettings.secondaryCol
        } 50%);
outline: none;
padding: 25px 50px;
cursor: pointer;
transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
color: ${this.props.buttonSettings.textCol};
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
border-radius: ${this.props.buttonSettings.borderRadius}px;
:hover {
    background: right top / 210% 100% linear-gradient(to right, ${this.props.buttonSettings.primaryCol} 50%, ${
            this.props.buttonSettings.secondaryCol
        } 50%);
    color: ${this.props.buttonSettings.textHoverCol};
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button2R;
