import React, { Component } from 'react';
import styled from 'styled-components';

const Search = styled.header`
    padding: 150px 30%;
    display: flex;
    flex-direction: row;
    background: #232323;
    form {
        width: auto;
        flex-grow: 1;
        padding: 0 20px 0 0;
        margin: 0;
        > input {
            box-sizing: border-box;
            background: #161616;
            outline: none;
            padding: 20px;
            width: 100%;
            color: #ffffff;
            border: none;
            font-weight: 600;
        }
    }
    > input {
        outline: none;
        background: #e4192f;
        border: none;
        color: #ebebeb;
        cursor: pointer;
        padding: 20px 50px;
        font-weight: 600;
    }
`;

class SearchField extends Component {
    render() {
        return (
            <Search>
                <form>
                    <input placeholder="Search by reference number" type="text" name="name" />
                </form>
                <input type="submit" value="Submit" />
            </Search>
        );
    }
}

export default SearchField;
