import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;

class Button4C extends Component {
    render() {
        const html = `<button>
    <span>Button</span>
</button>`;
        const css = `position: relative;
display: inline-block;
background: ${this.props.buttonSettings.primaryCol};
outline: none;
padding: 25px 50px;
cursor: pointer;
transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
color: ${this.props.buttonSettings.textCol};
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
border-radius: ${this.props.buttonSettings.borderRadius}px;
:before,
:after {
    content: '';
    position: absolute;
    top: 0;
    background: ${this.props.buttonSettings.secondaryCol};
    height: 2px;
    width: 0;
    left: 50%;
    transform: translate(-50%,0);
    transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
}
:after {
    top: auto;
    bottom: 0;
}
:hover {
    :before,
    :after {
        width: 100%;
    }
}
span {
    position: relative;
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    <span>
                        {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    </span>
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button4C;
