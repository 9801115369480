import React, { Component } from "react";
import styled from "styled-components";

const TextField = styled.div`
    display:flex;
    .textfield-title {
        background:#eee;
        padding:20px 30px 20px 25px;
    }

    .textfield-wrap {
        flex:1;

        input {
            padding:20px;
            width:100%;
            border:none;
            font-size:1rem;
            outline:none;
        }
    }
`;

class ButtonTextField extends Component {
    render() {
        return (
            <TextField>
                <p className="textfield-title">Button Text</p>

                <div className="textfield-wrap">
                    <input
                        type="text"
                        ref={(input) => this.input = input}
                        value={this.props.buttonSettings.buttonText}
                        onChange={() => this.props.setButtonSetting('buttonText', this.input.value)}
                        className="textfield-input"
                    />
                </div>
            </TextField>
        );
    }
}

export default ButtonTextField;
