import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;

class Button8BL extends Component {
    render() {
        const html = `<button>
    Button
    <span>Button</span>
    <span>Button</span>
</button>`;
        const css = `position: relative;
overflow: hidden;
display: inline-block;
background: ${this.props.buttonSettings.primaryCol};
outline: none;
padding: 25px 50px;
cursor: pointer;
transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
color: ${this.props.buttonSettings.textCol};
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
border-radius: ${this.props.buttonSettings.borderRadius}px;
span {
    position: relative;
    z-index: 1;
}
:before {
    content: '';
    position: absolute;
    background: ${this.props.buttonSettings.secondaryCol};
    bottom: 0;
    right: 0;
    height: 0;
    width: 0;
    border-radius: 50%;
    transition: inherit;
}
:hover {
    color: ${this.props.buttonSettings.textHoverCol};
    :before {
        width: 1em;
        height: 1em;
        transform: scale(20);
    }
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    <span className="apply-font">
                        {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    </span>
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button8BL;
