import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;

class Button3L extends Component {
    render() {
        const html = `<button>
    Button
    <span>Button</span>
    <span>Button</span>
</button>`;
        const css = `position: relative;
overflow: hidden;
display: inline-block;
outline: none;
padding: 25px 50px;
cursor: pointer;
color: rgba(255, 255, 255, 0);
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
border-radius: ${this.props.buttonSettings.borderRadius}px;
span {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    padding: 25px 50px;
    transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
    box-sizing: border-box;
    :nth-of-type(1) {
        background: ${this.props.buttonSettings.primaryCol};
        color: ${this.props.buttonSettings.textCol};
        left: 0;
    }
    :nth-of-type(2) {
        background: ${this.props.buttonSettings.secondaryCol};
        color: ${this.props.buttonSettings.textHoverCol};
        left: -100%;
    }
}
:hover {
    color: ${this.props.buttonSettings.textHoverCol};
    span {
        :nth-of-type(1) {
            left: 100%;
        }
        :nth-of-type(2) {
            left: 0;
        }
    }
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    <span className="apply-font">
                        {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    </span>
                    <span className="apply-font">
                        {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    </span>
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button3L;
