import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;

class Button6 extends Component {
    render() {
        const html = `<button>
    Button
    <span>Button</span>
    <span>Button</span>
</button>`;
        const css = `position: relative;
overflow: hidden;
display: inline-block;
outline: none;
padding: 25px 50px;
cursor: pointer;
color: rgba(255, 255, 255, 0);
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
background: ${this.props.buttonSettings.primaryCol};
border-radius: ${this.props.buttonSettings.borderRadius}px;
span {
    position: absolute;
    top: 0;
    left: 0;
    padding: inherit;
    font-family: inherit;
    transition: ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
    :nth-of-type(1) {
        background: ${this.props.buttonSettings.primaryCol};
        color: ${this.props.buttonSettings.textCol};
    }
    :nth-of-type(2) {
        background: none;
        color: ${this.props.buttonSettings.textHoverCol};
        transform: rotate(30deg);
        opacity: 0;
        transform-origin: -50% 0;
    }
}
:hover {
    span {
        :nth-of-type(1) {
            color: ${this.props.buttonSettings.secondaryCol};
            background: ${this.props.buttonSettings.secondaryCol};
        }
        :nth-of-type(2) {
            transform: rotate(0);
            opacity: 1;
        }
    }
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    <span className="apply-font">
                        {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    </span>
                    <span className="apply-font">
                        {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                    </span>
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button6;
