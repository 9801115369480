import React, { Component } from 'react';
import styled from 'styled-components';
import BezierEditor from 'bezier-easing-editor';
import raf from 'raf';

const Editor = styled.div`
    @media (min-width: 768px) {
        display: flex;
    }

    .editor-wrap {
        padding: 0 0 0 30px;
    }

    .editor-graph {
        @media (min-width: 568px) {
            margin-top: -25px;
        }
    }

    .editor-inner {
        select {
            padding: 15px;
            background: ${props => props.theme.darkGrey};
            color: white;
            border: none;
            font-size: 1rem;
            margin-bottom: 10px;
        }
    }

    .editor-val {
        font-size: 0.8rem;
    }

    .editor-filter {
        &:not(:last-child) {
            padding-bottom: 25px;
        }
    }
`;

const RangeBlock = styled.div`
    display: flex;
    align-items: center;
    input {
        margin-right: 20px;
    }
`;

class TimingEditor extends Component {
    state = {
        cubicBezier: [0.2, 0.2, 0.8, 0.8],
        progress: 0,
        isCustom: false,
        easingFunctions: {
            linear: {
                cubicBezier: [0.2, 0.2, 0.8, 0.8],
            },
            easeInSine: {
                cubicBezier: [0.47, 0, 0.745, 0.715],
            },
            easeOutSine: {
                cubicBezier: [0.39, 0.575, 0.565, 1],
            },
            easeInOutSine: {
                cubicBezier: [0.445, 0.05, 0.55, 0.95],
            },
            easeInQuad: {
                cubicBezier: [0.55, 0.085, 0.68, 0.53],
            },
            easeOutQuad: {
                cubicBezier: [0.25, 0.46, 0.45, 0.94],
            },
            easeInOutQuad: {
                cubicBezier: [0.455, 0.03, 0.515, 0.955],
            },
            easeInCubic: {
                cubicBezier: [0.55, 0.055, 0.675, 0.19],
            },
            easeOutCubic: {
                cubicBezier: [0.215, 0.61, 0.355, 1],
            },
            easeInOutCubic: {
                cubicBezier: [0.645, 0.045, 0.355, 1],
            },
            easeInQuart: {
                cubicBezier: [0.895, 0.03, 0.685, 0.22],
            },
            easeOutQuart: {
                cubicBezier: [0.165, 0.84, 0.44, 1],
            },
            easeInOutQuart: {
                cubicBezier: [0.77, 0, 0.175, 1],
            },
            easeInQuint: {
                cubicBezier: [0.755, 0.05, 0.855, 0.06],
            },
            easeOutQuint: {
                cubicBezier: [0.23, 1, 0.32, 1],
            },
            easeInOutQuint: {
                cubicBezier: [0.86, 0, 0.07, 1],
            },
            easeInExpo: {
                cubicBezier: [0.95, 0.05, 0.795, 0.035],
            },
            easeOutExpo: {
                cubicBezier: [0.19, 1, 0.22, 1],
            },
            easeInOutExpo: {
                cubicBezier: [1, 0, 0, 1],
            },
            easeInCirc: {
                cubicBezier: [0.6, 0.04, 0.98, 0.335],
            },
            easeOutCirc: {
                cubicBezier: [0.075, 0.82, 0.165, 1],
            },
            easeInOutCirc: {
                cubicBezier: [0.785, 0.135, 0.15, 0.86],
            },
            easeInBack: {
                cubicBezier: [0.6, -0.28, 0.735, 0.045],
            },
            easeOutBack: {
                cubicBezier: [0.175, 0.885, 0.32, 1.275],
            },
            easeInOutBack: {
                cubicBezier: [0.68, -0.55, 0.265, 1.55],
            },
        },
    };

    componentDidMount() {
        const loop = t => {
            this._loopId = raf(loop);
            this.setState({
                progress: (t / this.props.buttonSettings.animationSpeed) % 1,
            });
        };
        this._loopId = raf(loop);
    }

    handleTimingChange = () => {
        const { value } = this.easeSelect;
        const selectedBezier = this.state.easingFunctions[value].cubicBezier;

        this.setState(
            {
                cubicBezier: selectedBezier,
            },
            () => {
                this.props.setButtonSetting('cubicBezier', selectedBezier);
            }
        );
    };

    handleGraphChange = val => {
        this.easeSelect.value = 'custom';
        this.setState(
            {
                cubicBezier: val,
                isCustom: true,
            },
            () => {
                this.props.setButtonSetting('cubicBezier', val);
            }
        );
    };

    handleSpeedChange = () => {
        const { value } = this.speedRange;

        this.props.setButtonSetting('animationSpeed', value);
    };

    render() {
        return (
            <Editor>
                <div className="editor-graph">
                    <BezierEditor
                        value={this.state.cubicBezier}
                        onChange={this.handleGraphChange}
                        progress={this.state.progress}
                        background="#333"
                        gridColor="rgba(255,255,255, 0.1)"
                        progressColor="#fff"
                        curveColor="#fff"
                        curveWidth={3}
                        handleStroke={3}
                        handleRadius={6}
                        handleColor="#E3192E"
                    />
                </div>

                <div className="editor-wrap">
                    <div className="editor-filter">
                        <p className="filters-title">Animation Speed</p>
                        <RangeBlock>
                            <input
                                type="range"
                                ref={input => (this.speedRange = input)}
                                onChange={this.handleSpeedChange}
                                value={this.props.buttonSettings.animationSpeed}
                                step="10"
                                min="100"
                                max="1500"
                            />
                            <p className="rangeblock-val">{this.props.buttonSettings.animationSpeed}</p>
                        </RangeBlock>
                    </div>

                    <div className="editor-filter">
                        <p className="filters-title">Animation Timing</p>
                        <div className="editor-inner">
                            <select ref={select => (this.easeSelect = select)} onChange={this.handleTimingChange}>
                                {Object.keys(this.state.easingFunctions).map(name => (
                                    <option value={name}>{name}</option>
                                ))}
                                <option value="custom">Custom</option>
                            </select>
                            <p className="editor-val">
                                {`cubic-bezier(${this.state.cubicBezier.map(val => val.toFixed(2)).join(', ')})`}
                            </p>
                        </div>
                    </div>
                </div>
            </Editor>
        );
    }
}

export default TimingEditor;
