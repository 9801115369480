import React, { Component } from 'react';
import ColourPicker from './ColourPicker';

class ColourFilters extends Component {
    handlePrimaryColourChange = colour => {
        this.props.setButtonSetting('primaryCol', colour);
    };

    handleSecondaryColourChange = colour => {
        this.props.setButtonSetting('secondaryCol', colour);
    };

    handleTertairyColourChange = colour => {
        this.props.setButtonSetting('tertiaryCol', colour);
    };

    handleTextColourChange = colour => {
        this.props.setButtonSetting('textCol', colour);
    };

    handleTextHoverColourChange = colour => {
        this.props.setButtonSetting('textHoverCol', colour);
    };


    render() {
        return (
            <div className="filters-wrap">
                <div className="filters-colours">
                    <div className="filters-colour">
                        <div className="filters-title">Primary Colour</div>
                        <ColourPicker
                            color={this.props.buttonSettings.primaryCol}
                            changeMethod={this.handlePrimaryColourChange}
                        />
                    </div>
                    <div className="filters-colour">
                        <div className="filters-title">Secondary Colour</div>
                        <ColourPicker
                            color={this.props.buttonSettings.secondaryCol}
                            changeMethod={this.handleSecondaryColourChange}
                        />
                    </div>
                    <div className="filters-colour">
                        <div className="filters-title">Tertiary Colour</div>
                        <ColourPicker
                            color={this.props.buttonSettings.teriaryCol}
                            changeMethod={this.handleSecondaryColourChange}
                        />
                    </div>
                    <div className="filters-colour">
                        <div className="filters-title">Text Colour</div>
                        <ColourPicker
                            color={this.props.buttonSettings.textCol}
                            changeMethod={this.handleTextColourChange}
                        />
                    </div>
                    <div className="filters-colour">
                        <div className="filters-title">Text Hover Colour</div>
                        <ColourPicker
                            color={this.props.buttonSettings.textHoverCol}
                            changeMethod={this.handleTextHoverColourChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ColourFilters;
