import React, { Component } from 'react';
import styled from 'styled-components';


const SiteFilters = styled.div`
    background: #333;
    color: white;
    padding: 30px 20px;
    @media (min-width: 992px) {
        padding: 50px;
    }

    .filters-animations {
        display:flex;
        justify-content:center;
    }

    .filters-textfilters {
        display:flex;
        justify-content:space-between;
        max-width:700px;
        margin:0 auto;
    }

    .filters-title {
        margin-bottom: 15px;
        font-size: 1.1rem;
    }

    .filters-colours {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: 0 auto;
        .filters-colour {
            &:not(:first-child) {
                margin-left: 30px;
            }
        }
    }

    .filters-row {
        margin-top: 40px;

        @media (min-width: 992px) {
            margin-top: 60px;
            display: inline-flex;
        }
    }
    
    .filters-block {
        &:not(:last-child) {
            margin-right:30px;
        }
    }

    .filters-select {
        padding: 15px;
        background: ${props => props.theme.darkGrey};
        color: white;
        border: none;
        font-size: 1rem;
    }

    .filters-input {
        padding: 15px;
        background: ${props => props.theme.darkGrey};
        color: white;
        border:none;
    }

    .filters-list {
        @media (min-width: 992px) {
            margin-left: 20px;
        }
    }

    #font-picker {
        color:black;
        box-shadow:none;
        .dropdown-button {
            padding: 15px;
            background: ${props => props.theme.darkGrey};
            color: white;
            border: none;
            font-size: 1rem;
            height:54px;
        }

        .dropdown-icon {
            &::before {
                border-top:6px solid white;
            }
        }
    }
`;

class Filters extends Component {
    render() {
        return (
            <SiteFilters>
                {this.props.children}
            </SiteFilters>
        );
    }
}

export default Filters;
