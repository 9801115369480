import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;


class Button9alt extends Component {
    render() {
        const html = `<button class="strikethrough">Strikethrough</button>`;
        const css = `position: relative;
z-index: 1;
background: ${this.props.buttonSettings.primaryCol};
outline: none;
border: none;
padding: 25px 50px;
border-radius: ${this.props.buttonSettings.borderRadius}px;
color: ${this.props.buttonSettings.textCol};
cursor: pointer;
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
&::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${this.props.buttonSettings.secondaryCol};
    transform-origin: bottom center;
    transform: scaleY(0.1);
    transition: all ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
}
&:hover::before {
    transform: scaleY(1);
    background-color: ${this.props.buttonSettings.tertiaryCol};
}
&:hover {
    color: ${this.props.buttonSettings.textHoverCol};
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button9alt;
