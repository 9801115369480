import React, { Component } from 'react';
import styled from 'styled-components';
import ClipCopy from '../ClipCopy.js';

const Button = styled.button`
    ${props => props.styles}
`;


class Button12 extends Component {
    render() {
        const html = `<button class="strikethrough">Strikethrough</button>`;
        const css = `position: relative;
padding: 25px 50px;
outline: none;
border-radius: ${this.props.buttonSettings.borderRadius}px;
background: ${this.props.buttonSettings.primaryCol};
color: ${this.props.buttonSettings.textCol};
cursor: pointer;
font-weight: ${this.props.buttonSettings.fontWeight};
letter-spacing: ${this.props.buttonSettings.letterSpacing}px;
&::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 3px;
    background-color: ${this.props.buttonSettings.secondaryCol};
    transform-origin: center right;
    transform: scaleX(0) translateY(-50%);
    transition: transform ${this.props.buttonSettings.animationSpeed}ms cubic-bezier(${this.props.buttonSettings.cubicBezier});
}
&:hover {
    color: ${this.props.buttonSettings.secondaryCol};
      &::before {
      transform-origin: center left;
      transform: scaleX(1) translateY(-50%);
    }
}`;
        return (
            <>
                <Button className="apply-font" buttonSettings={this.props.buttonSettings} styles={css}>
                    {this.props.buttonSettings.buttonText ? this.props.buttonSettings.buttonText : 'Button'}
                </Button>
                <ClipCopy css={css} html={html} />
            </>
        );
    }
}

export default Button12;
