import React, { Component } from 'react';
import styled from 'styled-components';

const Range = styled.div`
    display: flex;
    align-items: center;
    input {
        margin-right: 20px;
    }
`;

class RangeSlider extends Component {
    handleChange = () => {
        this.props.handleChange(this.range.value);
    }

    render() {
        return (
           <Range>
               <input
                    type="range"
                    ref={input => (this.range = input)}
                    onChange={this.handleChange}
                    value={this.props.value}
                    step="1"
                    min="0"
                    max="40"
                />
                <p className="rangeblock-val">
                    {this.props.value}
                </p>
           </Range>
        );
    }
}

export default RangeSlider;
