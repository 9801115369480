import React, { Component } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ClipboardWrap = styled.div`
    width: 100%;
    padding: 15px 25px;
    margin: 60px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #eee;
    border-top: 1px solid #e0e0e0;
    @media (min-width:992px) {
        padding: 15px 18%;
    }
    .clipboard {
        position: relative;
        display: inline-block;
        outline: none;
        color: #333;
        transition: all 0.2s ease;
        font-weight: 500;
        cursor: pointer;
        padding: 0 5px 0 0;
        :hover {
            color: #e4192f;
        }
        :before {
            content: 'Copied';
            position: absolute;
            display: block;
            z-index: -1;
            transition: all 0.3s ease;
            color: #fff;
            background: rgba(228, 25, 47, 0.68);
            padding: 5px 10px;
            top: 50%;
            left: 50%;
            transform: translate(-55%, -50%);
        }
        &.__active {
            :before {
                transform: translate(-55%, -58px);
            }
        }
    }
`;

class ClipCopy extends Component {
    state = {
        currentActivePopup: null,
    };

    popupChange = e => {
        const clicked = e.target.id;
        this.setState({
            currentActivePopup: clicked,
        });
        setTimeout(
            function() {
                this.setState({
                    currentActivePopup: null,
                });
            }.bind(this),
            500
        );
    };

    render() {
        const zeplin =
        `${this.props.html}

${this.props.css}`;
        const myArray = [
            { buttonText: 'Zeplin', copyContent: zeplin },
            { buttonText: 'HTML', copyContent: this.props.html },
            { buttonText: 'CSS', copyContent: this.props.css },
        ];
        return (
            <>
                <ClipboardWrap currentActivePopup={this.state.currentActivePopup}>
                    {myArray.map((value, index) => <CopyToClipboard text={ myArray[index].copyContent }>
                            <button onClick={this.popupChange} className={this.state.currentActivePopup == index ? 'clipboard __active' : 'clipboard'} id={index}>{ myArray[index].buttonText }</button>
                        </CopyToClipboard>)}
                </ClipboardWrap>
            </>
        );
    }
}

export default ClipCopy;
